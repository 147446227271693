/* Global Styles */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #E0F0FF;
    color: #212529;
    padding: 0;
    line-height: 1.5;
	margin: 0;
}

p {
	margin: 0px;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}


.main-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
	padding-top: 60px;
	display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* Navbar */
.sticky-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

/* Footer */
.footer {
    width: 100%;
	margin-top: auto;
}

.footer a {
    text-decoration: none;
    color: inherit;
    margin: 0 5px;
    transition: color 0.3s ease;
}

.footer a:hover {
    text-decoration: underline;
}

.footer .MuiToolbar-root {
    justify-content: center;
    padding: 0;
}



/* Intro Elements */
.intro-container {
    display: flex;
    flex-direction: column;
    align-items: center;
	margin-bottom: 20px;
}

.intro-card, .loading-card {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	line-height: 1.8rem;
    background-color: #FFF;
}

h1 {
	font-size: 2.5rem;
	text-align: center;
}

/* Form elements */
.form-container {
    flex-direction: column;
    padding: 16px;
}

.input-row {
    display: flex;
    justify-content: center;
	align-items: center;
    width: 100%;
    gap: 50px;
    flex-wrap: nowrap;
}

.input-field {
    width: 200px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.input-field label, .interests-container label {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 5px;
}

.select-input {
    border-radius: 8px !important;
    width: 100%;
    max-width: 200px;
    margin-bottom: 25px;
    border: 2px solid #333;
    text-align: center;
    background-color: #FFF;
	font-size: 18px !important;
}

.interests-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.interest-input {
    border-radius: 8px !important;
    max-width: 200px !important;
    border: 2px solid #333 !important;
    background-color: #FFF;
	font-size: 18px !important;
}

.interest-input-container {
    margin-bottom: 25px;
	display: flex;
	alignItems: center;
}

.interests-chips {
    display: flex;
    flex-direction: row;
    gap: 25px;
    flex-wrap: wrap;
    justify-content: center;
}

.interest-chip {
    border-radius: 8px !important;
    font-size: 20px !important;
    margin: 2px !important;
	background-color: #FFF !important;
	padding: 20px 10px !important;
}

.button-wrapper {
    text-align: center;
    margin-top: 20px;
}

/* Results Message Container */

.loading-message-container {
    display: block;
    max-width: 600px;
    margin: 0 auto 20px;
    line-height: 3rem;
	text-align: center;
}

/* Output Display */

.card-container {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card {
    width: 100%;
    max-width: 600px;
    margin: 25px 0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	line-height: 1.8rem;
    background-color: #FFF;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-content h3 {
    width: 100%;
    font-size: 24px;
    margin: 0;
    text-align: center;
}

.amazon-button {
    width: 190px;
    height: 50px;
    margin-left: 15px;
	font-size: 15px;
	font-weight: 600;
    background-color: #0073e6;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.amazon-button:hover {
    background-color: #005bb5;
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}


/* Card Bounce Effect */

@keyframes bounceIn {
    0% {
        transform: scale(0.3);
        opacity: 0;
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.card.bounce {
    animation: bounceIn 0.5s forwards;
}


/* Load More Button */

.load-more-button, .submit-button {
    width: 250px;
    height: 50px;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 600;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.load-more-button:hover, .submit-button:hover {
    background-color: #388E3C;
    transform: scale(1.05);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

.load-more-button.loading {
    cursor: not-allowed;
	animation: pulse 2s infinite;
}

@keyframes scrollText {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.load-more-button.loading span {
    display: inline-block;
    white-space: nowrap;
    animation: scrollText 5s linear infinite;
	color: #E0F0FF;
}

/* Fade effects */
.fade-out {
    transition: opacity 1s ease;
}
.fade-in {
    transition: opacity 0.5s ease;
}

.fade-out {
    opacity: 0;
}

.fade-in {
    opacity: 1;
}

.search-choice-chip {
    border-radius: 8px !important;
    font-size: 20px !important;
	background-color: #FFF !important;
	padding: 20px 10px !important;
}

/* Add responsive styles at the end of your global.css file */

.footer-container {
    text-align: center;
}

.footer-container a {
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-container a:hover {
    color: #007BFF;
}


/* Media Queries for Responsiveness */
@media (max-width: 768px) {
	h1 {
		font-size: 2rem;
	}
    .intro-card, .loading-card, .card {
        width: 90%;
		margin-left: 5%;
		margin-right: 5%;
        max-width: none;
    }	
    .input-row {
        flex-direction: column;
        gap: 10px;
        align-items: stretch;
    }
    .input-field, .select-input, .interest-input {
        width: 90%;
		margin-left: 5%;
		margin-right: 5%;
        max-width: none;
    }
}

@media (max-width: 600px) {
	h1 {
		margin-bottom: 0px;
	}
    h1::after {
        content: "";
        display: block;
        width: 80%;
        margin: 20px auto 0; /* Adjust margin to position the line as desired */
        border-bottom: 2px solid #212529; /* Adjust border style to your preference */
    }
    .load-more-button, .submit-button {
        width: 70%;
		margin-left: 15%;
		margin-right: 15%;
        max-width: none;
    }
    .button-wrapper,
    .footer {
        text-align: left;
    }
	.intro-card {
		display: none;
	}
    .loading-card, .card {
        max-width: 100%;
        box-shadow: none;
    }	
    .header-content, .interest-input-container {
        flex-direction: column;
        align-items: stretch;
    }
    .amazon-button {
		width: 100%;
        margin-left: 0;
        margin-top: 15px;
    }
}
